import React from "react";
import CommonComp from "./common/CommonComp";
import marketAnalysisEn from "../../../assets/images/estimate-home/market-analysis-en.webp";
import marketAnalysisFr from "../../../assets/images/estimate-home/market-analysis-fr.webp";
import marketAnalysisNl from "../../../assets/images/estimate-home/market-analysis-nl.webp";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
const MarketAnalysisBlock = ({ alt }) => {
	const { t } = useTranslation("estimate-home");
	const { locale } = useRouter();
	return (
		<CommonComp
			alt={alt}
			sectionImg={
				locale === "fr"
					? marketAnalysisFr
					: locale === "nl"
					? marketAnalysisNl
					: marketAnalysisEn
			}
			headingText={t("comparative-market-analysis")}
			paraOne={t("comparative-market-analysis-paragraph-one")}
			paraTwo={t("comparative-market-analysis-paragraph-two")}
		/>
	);
};

export default MarketAnalysisBlock;
