import React, { useState } from "react";
import propertyTypeIcon from "../../../assets/images/estimate-home/property.svg";
import locationIcon from "../../../assets/images/estimate-home/location.svg";
import garageIcon from "../../../assets/images/estimate-home/garage.svg";
import typeOfBuildingIcon from "../../../assets/images/estimate-home/type_of_building.svg";
import stateOfPropertyIcon from "../../../assets/images/estimate-home/state_of_property.svg";
import extraAddedValueIcon from "../../../assets/images/estimate-home/extra_added_value.svg";
import habitableIcon from "../../../assets/images/estimate-home/habitable.svg";
import numberOfBedroomsIcon from "../../../assets/images/estimate-home/number_of_bedrooms.svg";
import constructionYearIcon from "../../../assets/images/estimate-home/construction_year.svg";
// import impactsFr from "../../../assets/images/estimate-home/impacts-value-fr.webp";
// import impactsEn from "../../../assets/images/estimate-home/impacts-value-en.webp";
// import impactsNl from "../../../assets/images/estimate-home/impacts-value-nl.webp";
import impactsFr from "../../../assets/images/estimate-home/impacts-value-fr.png";
import impactsEn from "../../../assets/images/estimate-home/impacts-value-en.png";
import impactsNl from "../../../assets/images/estimate-home/impacts-value-nl.png";
import impactsSmNl from "../../../assets/images/estimate-home/impacts-value-sm-nl.webp";
import impactsSmFr from "../../../assets/images/estimate-home/impacts-value-sm-fr.webp";
import impactsSmEn from "../../../assets/images/estimate-home/impacts-value-sm-en.webp";

import styled from "styled-components";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import { useRouter } from "next/router";
import { useSwipeable } from "react-swipeable";

const MainHeading = styled.h2`
	font-family: "Nunito Sans";
	font-style: normal;
	font-size: 32px;
	line-height: 44px;
	padding: 0px 10px;
	color: var(--mainColor);
	font-weight: 700;

	@media (max-width: 525px) {
		text-align: center;
	}
	@media (max-width: 768px) {
		font-size: 20px;
		line-height: 27px;
	}
`;

const ParaDiv = styled.p`
	font-family: "Nunito Sans";
	font-style: normal;
	font-weight: 400;

	color: var(--mainColor);
	font-size: 16px;
	line-height: 22px;
	@media (max-width: 525px) {
		font-size: 14px;
		line-height: 19px;
		text-align: center;
	}
`;

const ImageContainer = styled.div`
	height: 270px;
	position: relative;
	@media (min-width: 768px) and (max-width: 1023px) {
		height: 245px;
	}
	@media (min-width: 1024px) {
		height: 300px;
	}
`;

const ImpactBlock = ({ alt }) => {
	const { t } = useTranslation("estimate-home");
	const { locale } = useRouter();
	const [activeIndex, setActiveIndex] = useState(0);

	const sections = [
		{
			icon: propertyTypeIcon,
			title: t("property-type"),
			para: t("property-type-paragraph"),
		},
		{
			icon: locationIcon,
			title: t("location"),
			para: t("location-paragraph"),
		},
		{
			icon: garageIcon,
			title: t("garage"),
			para: t("garage-paragraph"),
		},
		{
			icon: typeOfBuildingIcon,
			title: t("type-of-building"),
			para: t("type-of-building-paragraph"),
		},
		{
			icon: stateOfPropertyIcon,
			title: t("state-of-the-property"),
			para: t("state-of-the-property-paragraph"),
		},
		{
			icon: extraAddedValueIcon,
			title: t("extra-added-value"),
			para: t("extra-added-value-paragraph"),
		},
		{
			icon: habitableIcon,
			title: t("habitable-surface"),
			para: t("habitable-surface-paragraph"),
		},
		{
			icon: numberOfBedroomsIcon,
			title: t("number-of-bedrooms"),
			para: t("number-of-bedrooms-paragraph"),
		},
		{
			icon: constructionYearIcon,
			title: t("construction-year"),
			para: t("construction-year-paragraph"),
		},
	];

	const handlers = useSwipeable({
		onSwipedRight: () =>
			activeIndex > 0 ? setActiveIndex(activeIndex - 1) : null,
		onSwipedLeft: () =>
			activeIndex < 5 ? setActiveIndex(activeIndex + 1) : null,
		trackMouse: true,
	});

	return (
		<div style={{ marginTop: 70 }} className="w-100">
			<div className="d-flex flex-column align-items-center justify-content-center">
				<MainHeading>{t("impacts-the-value-of-home")}</MainHeading>
				<ImageContainer className="w-100">
					<Image
						alt={alt}
						className="d-none d-md-block"
						objectFit="contain"
						layout="fill"
						quality={100}
						src={
							locale === "en"
								? impactsEn
								: locale === "fr"
								? impactsFr
								: impactsNl
						}
					/>
					<Image
						alt={alt}
						className="d-block d-md-none"
						objectFit="contain"
						layout="fill"
						src={
							locale === "en"
								? impactsSmEn
								: locale === "fr"
								? impactsSmFr
								: impactsSmNl
						}
					/>
				</ImageContainer>
			</div>
			<div
				style={{ marginTop: 50 }}
				className="d-none d-md-flex flex-row flex-wrap container"
			>
				{sections.map((section) => (
					<div
						key={section.title}
						className="d-flex flex-column align-items-center align-items-md-start col-12 col-md-6 col-lg-4 mb-4"
					>
						<div className="d-flex flex-row mb-2">
							<img
								className="mr-2"
								src={section.icon}
								width={17}
								height={17}
								style={{ objectFit: "contain" }}
								alt="icon"
							/>
							<h3
								style={{
									fontSize: 16,
									fontWeight: "bold",
									color: "var(--mainColor)",
								}}
							>
								{section.title}
							</h3>
						</div>
						<ParaDiv>{section.para}</ParaDiv>
					</div>
				))}
			</div>
			<div
				style={{ marginTop: 50 }}
				{...handlers}
				className="d-block d-md-none col-12"
			>
				<div className="d-flex flex-row flex-wrap container">
					{sections
						.slice(activeIndex * 2, activeIndex * 2 + 2)
						.map((_section) => {
							return (
								<div
									key={_section.title}
									className="d-flex flex-column align-items-center align-items-md-start col-12 col-md-6 col-lg-4 mb-4"
								>
									<div className="d-flex flex-row mb-2">
										<img
											className="mr-2"
											src={_section.icon}
											width={17}
											height={17}
											style={{ objectFit: "contain" }}
											alt="icon"
										/>
										<h3
											style={{
												fontSize: 16,
												fontWeight: "bold",
												color: "var(--mainColor)",
											}}
										>
											{_section.title}
										</h3>
									</div>
									<ParaDiv>{_section.para}</ParaDiv>
								</div>
							);
						})}
				</div>
				<div className="d-flex flex-row align-items-center justify-content-center">
					{Array(5)
						.fill(0)
						.map((_, index) => {
							if (index === activeIndex) {
								return (
									<div
										key={`${index}`}
										onClick={() => setActiveIndex(index)}
										style={{
											width: 16,
											height: 16,
											backgroundColor: "var(--colorBlue)",
											borderRadius: 30,
											margin: "0px 2px",
										}}
									/>
								);
							} else {
								return (
									<div
										key={`${index}`}
										onClick={() => setActiveIndex(index)}
										style={{
											width: 16,
											height: 16,
											background: "rgba(56, 113, 239, 0.25)",
											border: "2px solid #FFFFFF",
											borderRadius: 30,
											margin: "0px 2px",
											animationDuration: "1s",
										}}
									/>
								);
							}
						})}
				</div>
			</div>
		</div>
	);
};

export default ImpactBlock;
