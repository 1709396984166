import { useTranslation } from "next-i18next";
import React, { useState } from "react";
import styled from "styled-components";
import Collapse from "antd/lib/collapse";
import { Button } from "antd";
import LoadingSvg from "../../../assets/images/estimate-home/loading.svg";
import { useLinkTranslation } from "../../../custom-hooks/useLinkTranslation";
import Image from "next/image";

const StyledButton = styled(Button)`
	background: #f2f6ff;
	border-radius: 8px;
	height: 46px;
	width: 224px;
	color: #3871ef;
	font-size: 14;
	border: none;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 5px;
	font-weight: 400;

	@media (max-width: 768px) {
		width: 90%;
	}
`;
const MainHeading = styled.h2`
	font-family: "Nunito Sans";
	font-style: normal;
	font-size: 32px;
	line-height: 44px;
	color: var(--mainColor);
	font-weight: 700;
	margin-bottom: 40px;

	@media (max-width: 525px) {
		text-align: center;
	}
	@media (max-width: 768px) {
		font-size: 20px;
		line-height: 27px;
	}
`;

const FaqBlock = ({ faqs, ...props }) => {
	const { t } = useTranslation("estimate-home");
	const [faqsLength, setFaqsLength] = useState(5);
	const { translateLink } = useLinkTranslation();
	return (
		<div
			style={{ marginTop: 50, ...props.style }}
			className={`w-100 ${props.className}`}
			{...props}
		>
			<div className="d-flex flex-column align-items-center justify-content-center">
				<MainHeading>{t("frequently-asked-question")}</MainHeading>
				<div className="col-12 col-md-10 col-lg-6">
					<Collapse defaultActiveKey={[faqs[0]?.question]} ghost>
						{faqs.slice(0, faqsLength).map((faq) => {
							return (
								<Collapse.Panel
									style={{ borderBottom: "1px solid #E5E5E5", marginTop: 10 }}
									key={faq?.question}
									header={
										<h3
											style={{
												fontSize: "18px",
												fontWeight: "bold",
												color: "var(--mainColor)",
												fontFamily: "Nunito Sans",
											}}
										>
											{faq?.question}
										</h3>
									}
								>
									<div>
										<div
											style={{ color: "var(--mainColor)" }}
											dangerouslySetInnerHTML={{
												__html: faq?.answer.replace(
													"$find-agent",
													translateLink("/agencies")
												),
											}}
										/>
									</div>
								</Collapse.Panel>
							);
						})}
					</Collapse>
				</div>
				{faqsLength <= faqs.length && (
					<div
						style={{ marginTop: 50 }}
						className="col-12 col-md-4 col-lg-4 d-flex align-items-center justify-content-center"
					>
						<StyledButton
							onClick={() => setFaqsLength(faqsLength + 5)}
							icon={<Image src={LoadingSvg} height={18} width={18} />}
							style={{}}
						>
							{t("load-more")}
						</StyledButton>
					</div>
				)}
			</div>
		</div>
	);
};

export default FaqBlock;
