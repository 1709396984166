import React from "react";
import { useTranslation } from "next-i18next";
import pricingMethodEn from "../../../assets/images/estimate-home/pricing-method-en.webp";
import pricingMethodFr from "../../../assets/images/estimate-home/pricing-method-fr.webp";
import pricingMethodNl from "../../../assets/images/estimate-home/pricing-method-nl.webp";
import { useRouter } from "next/router";
import CommonComp from "./common/CommonComp";

const PricingMethodBlock = ({ alt }) => {
	const { t } = useTranslation("estimate-home");
	const { locale } = useRouter();
	return (
		<CommonComp
			alt={alt}
			sectionImg={
				locale === "fr"
					? pricingMethodFr
					: locale === "nl"
					? pricingMethodNl
					: pricingMethodEn
			}
			headingText={t("hedonistic-pricing-method")}
			paraOne={t("hedonistic-pricing-method-paragraph-one")}
			paraTwo={t("hedonistic-pricing-method-paragraph-two")}
		/>
	);
};

export default PricingMethodBlock;
