import Image from "next/image";
import React from "react";
import styled from "styled-components";

const MainHeading = styled.h2`
	font-family: "Nunito Sans";
	font-style: normal;
	font-size: 32px;
	line-height: 44px;
	color: var(--mainColor);
	font-weight: 700;

	@media (max-width: 525px) {
		text-align: center;
	}
	@media (max-width: 768px) {
		font-size: 20px;
		line-height: 27px;
	}
`;

const ParaDiv = styled.p`
	font-family: "Nunito Sans";
	font-style: normal;
	font-weight: 400;
	color: var(--mainColor);
	font-size: 16px;
	line-height: 22px;
	@media (max-width: 525px) {
		font-size: 14px;
		line-height: 19px;
	}
`;

const ImageContainer = styled.div`
	width: 100%;
	height: 280px;

	@media (min-width: 768px) and (max-width: 1023px) {
		height: 280px;
	}

	@media (min-width: 1024px) and (max-width: 1999px) {
		height: 450px;
	}

	@media (min-width: 1200px) {
		height: 450px;
	}
`;

const CommonComp = ({
	sectionImg,
	headingText,
	paraOne,
	paraTwo,
	flexClass = "flex-column flex-md-row",
	className = "",
	alt = "",
}) => {
	return (
		<div
			className={`container d-flex flex-wrap align-items-center mt-5 ${flexClass} ${className}`}
		>
			<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex flex-column justify-content-center  px-md-4">
				<MainHeading> {headingText ? headingText : ""}</MainHeading>
				<ParaDiv className="my-2">{paraOne}</ParaDiv>
				<br />
				<ParaDiv className="my-2">{paraTwo ? paraTwo : ""}</ParaDiv>
			</div>
			<div
				style={{ position: "relative" }}
				className="col-xl-6 col-lg-6 col-md-6 col-sm-12 h-100"
			>
				<ImageContainer>
					<Image
						quality={100}
						alt={alt}
						src={sectionImg}
						layout="fill"
						objectFit="contain"
					/>
				</ImageContainer>
			</div>
		</div>
	);
};

export default CommonComp;
