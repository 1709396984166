import AutoComplete from "antd/lib/auto-complete";
import Button from "antd/lib/button";
import Input from "antd/lib/input";
import { useTranslation } from "next-i18next";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import uptodateEstimation from "../../assets/images/estimate-home/up-to-date-estimation.webp";
import freeEstimation from "../../assets/images/estimate-home/free-estimation.webp";
import instantValuation from "../../assets/images/estimate-home/instant-valuation.webp";
import bgSm from "../../assets/images/estimate-home/cover-sm.png";
import bgMd from "../../assets/images/estimate-home/cover-md.png";
import bgCover from "../../assets/images/estimate-home/cover-lg.png";
import blockNl from "../../assets/images/estimate-home/cover-block-nl.svg";
import blockFr from "../../assets/images/estimate-home/cover-block-fr.svg";
import blockEn from "../../assets/images/estimate-home/cover-block-en.svg";
import SectionOne from "./SubComponents/SectionOne";
import HouseWorthBlock from "./SubComponents/HouseWorthBlock";
import PricingMethodBlock from "./SubComponents/PricingMethodBlock";
import SalesMethod from "./SubComponents/SalesMethod";
import MarketAnalysisBlock from "./SubComponents/MarketAnalysisBlock";
import { searchaddressOnMapbox } from "../../network-requests";
import { useDispatch } from "react-redux";
import { useRouter } from "next/router";
import { openMainStepsAction } from "../../actions";
// import SectionThree from "./SubComponents/SectionThree";
import { useLinkTranslation } from "../../custom-hooks/useLinkTranslation";
import Breadcrumb from "antd/lib/breadcrumb";
import Image from "next/image";
import Loading from "../Loading";
import ImpactBlock from "./SubComponents/ImpactsBlock";
import FaqBlock from "./SubComponents/FaqBlock";
import GoogleRatingBlock from "../GoogleRatingBlock";
import { formatToCurrencyForCount } from "../CostCalculator/city";
import { getCountOfSaleRentProperties } from "../../network-requests/search-engine";

//  const MainContainer = styled.div`
//  	border-radius: 10px;
//  `;

const MainHeading = styled.h2`
	font-family: "Nunito Sans";
	font-style: normal;
	font-size: 32px;
	line-height: 44px;
	color: var(--mainColor);
	font-weight: bold;
	@media (max-width: 525px) {
		font-size: 20px;
		line-height: 25px;
		text-align: center;
	}
	@media (max-width: 768px) {
		font-size: 28px;
		line-height: 38px;
	}
`;

const MainHeadingTop = styled.h1`
	font-family: "Nunito Sans";
	font-style: normal;
	font-weight: 700;
	font-size: 28px;
	max-width: 450px;
	margin-bottom: 0px;
	line-height: 32px;
	margin-top: 20px;
	text-align: center;

	@media (min-width: 768px) and (max-width: 1023px) {
		font-size: 28px;
		line-height: 38px;
		margin-top: 0px;
		text-align: start;
	}
	@media (min-width: 1024px) {
		font-size: 42px;
		line-height: 44px;
		margin-top: 0px;
		text-align: start;
	}
`;

const MainPara = styled.h2`
	font-family: "Nunito Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	padding-bottom: 10px;
	@media (max-width: 525px) {
		text-align: center;
	}
	@media (max-width: 768px) {
		font-size: 16px;
		line-height: 22px;
	}
`;

const ParaDiv = styled.div`
	font-family: "Nunito Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	color: var(--mainColor);
	line-height: 22px;
	@media (max-width: 525px) {
		font-size: 14px;
		line-height: 19px;
		text-align: center;
	}
`;

const SubContainer = styled.div`
	@media (max-width: 575px) {
		display: flex;
		flex-wrap: wrap;
		flex-direction: column-reverse;
		justify-content: center;
	}
	@media (min-width: 575px) {
		display: flex;
		flex-wrap: wrap;
		flex-direction: column-reverse;
		justify-content: center;
	}
	@media (min-width: 768px) {
		flex-direction: row;
	}
`;

const InputContainer = styled.div`
	justify-content: flex-end;
	text-align: center;
	@media (min-width: 600px) {
		justify-content: center;
		text-align: left;
	}

	@media (max-width: 575px) {
		padding: 27px;
		input {
			min-width: 100%;
			border-radius: 8;
			border: 1px solid #a9c0ff;
			height: 50;
			margin: 0 15px;
		}
	}
	@media (min-width: 575px) {
		padding: 10px 10px;
		input {
			border: 1px solid #a9c0ff;
			margin-left: 1em;
		}
	}
`;
const HeadingContainer = styled.div`
	font-weight: bold;
`;

const ButtonContainer = styled.div`
	button {
		font-family: var(--fontNunitoBold);
		font-size: 16px;
		line-height: 22px;
		min-width: 185px;
		height: 45px;
		background: var(--colorBlue);
		border-radius: 8px;
		color: #fff;
	}
	@media (min-width: 769px) {
		margin-left: 20px;
	}
	@media (max-width: 768px) {
		margin: 20px 0 0;
	}
`;

const ImageContainerThree = styled.div`
	@media (max-width: 540px) {
		display: none;
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
	@media (min-width: 768) {
		display: none;
	}
	@media (min-width: 1024) {
		display: none;
	}
	/* @media (min-width: 768px) {
    img {
      min-width: inherit;
    } */
	/* } */
`;

const BackgroundContainer = styled.div`
	height: 590px;
	width: 100%;
	border-radius: 0px;
	position: relative;
	margin-bottom: 110px;
	@media (min-width: 575px) and (max-width: 768px) {
		height: 560px;
		border-radius: 0px;
		margin-bottom: 100px;
	}
	@media (min-width: 769px) and (max-width: 1024px) {
		height: 480px;
		border-radius: 10px;
		margin-bottom: 150px;
	}
	@media (min-width: 1025px) {
		border-radius: 10px;
		height: 650px;
		margin-bottom: 150px;
	}
`;

const SeearcyhBlock = styled.div`
	@media (min-width: 769px) {
		flex-direction: row;
	}
	@media (max-width: 768px) {
		flex-direction: column;
	}
`;

const MainHeading2 = styled.h2`
	font-family: "Nunito Sans";
	font-style: normal;
	font-weight: 700;
	font-size: 28px;
	line-height: 38px;
	text-align: center;
	color: var(--mainColor);
	padding: 0px 20px;

	@media (max-width: 525px) {
		text-align: center;
	}
	@media (max-width: 768px) {
		font-size: 20px;
		line-height: 27px;
	}
`;

const ParaDiv2 = styled.h3`
	font-family: "Nunito Sans";
	font-style: normal;
	font-weight: 400;
	color: var(--mainColor);
	font-size: 16px;
	line-height: 22px;
	text-align: center;

	margin: 0px 25%;

	@media (max-width: 1024px) {
		margin: 0px 15%;
	}

	@media (max-width: 768px) {
		margin: 0px 30px;
	}

	@media (max-width: 525px) {
		font-size: 14px;
		line-height: 19px;
	}
`;

const StatContainer = styled.div`
.stat-container {
	margin-top: 20px;
	gap: 16px;
	flex-direction: column;
	padding: 0px 30px;
	margin-right: auto;
	margin-left: auto;

	@media (min-width: 768px) and (max-width: 1023px) {
		margin-top: 0px;
		padding: 0px 225px;
		flex-direction: row;
	}

	@media (min-width: 1024px) and (max-width: 1200px) {
		margin-top: 20px;
		flex-direction: row;
		padding: 0px 225px;
	}

	@media (min-width: 1201px) {
		margin-top: 50px;
		flex-direction: row;
		padding: 0px 225px;
	}

	.stat {
		border-top: 2px solid var(--colorBlue);
		border-bottom: none;
		padding-top: 12px;
		margin-bottom: 10px;
		width: 100%;
		height: min-content;

		@media (min-width: 768px) and (max-width: 1023px) {
			margin-bottom: 30px;
		}

		@media (min-width: 1024px) {
			margin-bottom: 50px;
		}

		@media (min-width: 768px) {
			border-left: 4px solid var(--colorBlue);
			border-top: none;
			padding-left: 20px;
			margin-right: 20px;
			padding-top: 0px;
		}

		.val {
			color: var(--mainColor);
			font-family: "Nunito Sans";
			font-size: 20px;
			font-weight: bold;
			// line-height: px;
			margin-bottom: 0px;
			margin-top: -8px;

			@media (min-width: 768px) and (max-width: 1023px) {
				font-size: 26px;
				line-height: 36px;
			}

			@media (min-width: 1024px) {
				font-size: 32px;
				line-height: 44px;
			}
		}

		.stat-text {
			color: var(--mainColor);
			font-family: "Nunito Sans";
			font-size: 14px;
			font-weight: 400;
			line-height: 16px;

			@media (min-width: 768px) {
				font-size: 16px;
				line-height: 22px;
			}
		}
	}
}
`;

const EstimateHome = ({ faqs, rating, hideCover = false }) => {
	const { t } = useTranslation(["estimate-home", "common"]);
	const { t: t2 } = useTranslation("seo-home-page");
	const router = useRouter();
	const agencyName = router.query?.agencyName;
	const source = router.query?.source;
	const [geoLocation, setGeoLocation] = useState({
		lat: null,
		lng: null,
	});
	const [dataInfo, setData] = useState({});
	const [value, setValue] = useState("");
	const [totalCount, setTotalCount] = useState();
	const [countForSale, setCountForSale] = useState();
	const [countForRent, setCountForRent] = useState();
	const [countForAgencies, setCountForAgencies] = useState();
	const [isLoading, setIsLoading] = useState(false);
	const dispatch = useDispatch();
	const [dataFromMapBox, setdataFromMapbox] = useState([]);
	const { routerPush } = useLinkTranslation();
	let SectionOneArray = [
		{
			imgSrc: instantValuation,
			heading: t("an-instant-valuation"),
			para: t("instant-valuation-paragraph"),
			alt: t("alt-instant-valuation"),
		},
		{
			imgSrc: freeEstimation,
			heading: t("a-free-estimation"),
			para: t("free-estimation-paragraph"),
			alt: t("alt-free-estimation"),
		},
		{
			imgSrc: uptodateEstimation,
			heading: t("up-to-date-estimation"),
			para: t("up-to-date-estimation-paragraph"),
			alt: t("alt-up-to-date"),
		},
	];

	const handleSelectAddress = (addressId: string) => {
		const [currentAddress] = dataFromMapBox.filter(
			(list) => list.id === addressId
		);
		setValue(currentAddress.fullAddress);

		const dataForNextStep = {
			locality:
				currentAddress.locality.length > 1
					? currentAddress.locality
					: currentAddress.place,
			number: currentAddress.number,
			street: currentAddress.street,
			zip: currentAddress.postcode,
			country: currentAddress.country,
		};

		setData({ ...dataForNextStep });
		setGeoLocation(currentAddress.location);
		setdataFromMapbox([]);
	};

	const handleAutocomplete = async (el) => {
		setValue(el.target.value);
		if (el.target.value.length > 0) {
			const suggessions = await searchaddressOnMapbox(
				el.target.value,
				"address"
			);
			setdataFromMapbox([...suggessions]);
		} else {
			setValue("");
		}
	};

	const goToMainSteps = () => {
		if (value.length === 0) {
			return false;
		}

		const data = {
			infoFromAutoComplete: value,
			location: { ...geoLocation },
			additionalAddress: { ...dataInfo },
		};

		dispatch(openMainStepsAction(data));
		let params = "";
		if (agencyName) {
			params = `agencyName=${agencyName}`;
		}
		if (source) {
			params += ` source=${source}`;
		}
		params = params.length > 0 ? "?" + params : params;
		params = params.replace(" ", "&");

		routerPush(`/new-estimate${params}`, undefined, { locale: router.locale });
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				const saleRentCounts = await getCountOfSaleRentProperties();
				setTotalCount(saleRentCounts.total_properties_count);
				setCountForSale(saleRentCounts.for_sale_count);
				setCountForRent(saleRentCounts.for_rent_count);
				setCountForAgencies(saleRentCounts.agencies_count);
			} catch (error) {
				console.error("Error fetching counts:", error);
			}
		};

		fetchData();
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		const handleRouteChangeStart = () => {
			setIsLoading(true);
		};

		const handleRouteChangeComplete = () => {
			setIsLoading(false);
		};

		router.events.on("routeChangeStart", handleRouteChangeStart);
		router.events.on("routeChangeComplete", handleRouteChangeComplete);

		return () => {
			router.events.off("routeChangeStart", handleRouteChangeStart);
			router.events.off("routeChangeComplete", handleRouteChangeComplete);
		};
	}, [router]);
	if (isLoading) {
		return <Loading />;
	}
	return (
		<div className="col-12 px-0 px-lg-3" style={{ background: "white" }}>
			{!hideCover && (
				<BackgroundContainer>
					<Image
						alt="alt-cover"
						src={bgCover}
						layout="fill"
						objectFit="cover"
						objectPosition={"right"}
						style={{ borderRadius: 10 }}
						className="d-none d-lg-block"
						priority
						quality={100}
					/>

					<Image
						alt="alt-cover"
						src={bgMd}
						layout="fill"
						objectFit="cover"
						className="d-none d-md-block d-lg-none"
						priority
					/>

					<img
						className="d-none d-md-block"
						style={{ position: "absolute", right: 50, bottom: 70 }}
						src={
							router.locale === "en"
								? blockEn
								: router.locale === "nl"
									? blockNl
									: blockFr
						}
					/>

					<Image
						alt="alt-cover"
						src={bgSm}
						layout="fill"
						objectFit="cover"
						className="d-block d-md-none"
						priority
					/>
					<InputContainer className="d-flex flex-wrap flex-column justify-content-start justify-content-md-center align-items-end col-lg-7 col-xl-6 col-md-6 col-sm-12 h-100">
						<div className="col-lg-11 col-sm-12 col-md-11 d-flex flex-column justify-content-start justify-content-md-center p-0 p-md-3">
							<MainHeadingTop className="text-light">
								{t("house-worth")}
							</MainHeadingTop>
							<MainPara className="text-white my-3">
								{t("estimation-in-three-ways")}
							</MainPara>
							<SeearcyhBlock className="image-carousel__popup d-flex">
								<div className="w-100 estimate-home-searchbar">
									<div className="custom-autocomplete position-relative">
										<AutoComplete
											style={{ width: "100%" }}
											options={dataFromMapBox.map(({ fullAddress, id }) => {
												return {
													label: fullAddress,
													value: id,
												};
											})}
											onSelect={(value, option) => {
												handleSelectAddress(value);
											}}
											value={value}
										>
											<Input
												size="large"
												className="ml-0"
												placeholder={t("property-adress-placeholder")}
												style={{
													borderRadius: 6,
													border: "1px solid #8F99B4",
													height: 46,
													width: "100%",
												}}
												onChange={(e) => handleAutocomplete(e)}
											/>
										</AutoComplete>
									</div>
								</div>
								<ButtonContainer>
									<Button
										// color="#FE7F2D"
										onClick={goToMainSteps}
										type="primary"
										style={{ background: "#FE7F2D", border: "none" }}
									>
										{t("get-free-estimation")}
									</Button>
								</ButtonContainer>
							</SeearcyhBlock>
						</div>
					</InputContainer>
					<div
						className="w-100 d-flex flex-row justify-content-center justify-content-md-start estimate-home-rating-block"
						style={{
							position: "absolute",
							left: 0,
							bottom: 0,
						}}
					>
						<GoogleRatingBlock rating={rating} isDarkBackground={true} />
					</div>
				</BackgroundContainer>
			)}
			<div className="container d-flex justify-content-center flex-column align-items-center">
				<div style={{ textAlign: "center", marginBottom: 50 }}>
					<MainHeading className="px-md-3">{t("estimate-house")}</MainHeading>
					<ParaDiv style={{ marginTop: -5 }} className="">
						{t("realestate-prices-and-online-houses")}
					</ParaDiv>
				</div>
				<div className="container d-flex flex-wrap w-100">
					{SectionOneArray.map((el, index) => {
						return (
							<SectionOne
								key={`${index}`}
								imgSrc={el.imgSrc}
								elHeading={el.heading}
								para={el.para}
							/>
						);
					})}
				</div>
			</div>
			<StatContainer>
				<div
					style={{ height: "max-content" }}
					className="w-100 d-flex stat-container"
				>
					<div
						style={{ gap: 20 }}
						className="w-100 d-flex flex-row align-items-start justify-content-between"
					>
						<div className="stat">
							<div className="val">
								{formatToCurrencyForCount(totalCount)}
							</div>
							<div
								dangerouslySetInnerHTML={{
									__html: t2("total-number-of-properties"),
								}}
								className="stat-text"
							/>
						</div>
						<div className="stat">
							<div className="val">
								{formatToCurrencyForCount(countForAgencies)}
							</div>
							<div
								className="stat-text"
								dangerouslySetInnerHTML={{
									__html: t2("total-number-of-agencies"),
								}}
							/>
						</div>
					</div>
					<div
						style={{ gap: 20 }}
						className="w-100 d-flex flex-row align-items-center justify-content-end"
					>
						<div className="stat">
							<div className="val">
								{formatToCurrencyForCount(countForSale)}
							</div>
							<div
								dangerouslySetInnerHTML={{
									__html: t2("total-number-for-sale"),
								}}
								className="stat-text"
							/>
						</div>
						<div className="stat">
							<div className="val">
								{formatToCurrencyForCount(countForRent)}
							</div>
							<div
								className="stat-text"
								dangerouslySetInnerHTML={{
									__html: t2("total-number-for-rent"),
								}}
							/>
						</div>
					</div>
				</div>
			</StatContainer>
			<HouseWorthBlock alt={t("alt-house-worth")} className="mt-4" />

			<FaqBlock faqs={faqs} className="d-lg-none" />
			<ImpactBlock alt={t("alt-impact-value")} />
			<FaqBlock faqs={faqs} className="d-none d-lg-block" />

			<div
				style={{ marginBottom: "50px", marginTop: 80 }}
				className="d-flex flex-column justify-content-center align-items-center"
			>
				<MainHeading2>{t("price-of-house-or-appartment")}</MainHeading2>
				<ParaDiv2>{t("price-of-house-or-appartment-paragraph")}</ParaDiv2>
			</div>
			<PricingMethodBlock alt={t("alt-pricing-method")} />
			<SalesMethod alt={t("alt-repeat-sales")} />
			<MarketAnalysisBlock alt={t("alt-competitve-market")} />
			<div style={{ height: 100 }} />
		</div>
	);
};

export default EstimateHome;
