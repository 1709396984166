import React from "react";
import sectionTwo from "../../../assets/images/estimate-home/house-worth-block.png";
import CommonComp from "./common/CommonComp";
import { useTranslation } from "next-i18next";
const HourWorthBlock = ({ className, alt }) => {
	const { t } = useTranslation("estimate-home");

	return (
		<CommonComp
			alt={alt}
			className={className}
			sectionImg={sectionTwo}
			headingText={t("my-house-worth")}
			paraOne={t("house-worth-paragraph-one")}
			paraTwo={t("house-worth-paragraph-two")}
		/>
	);
};

export default HourWorthBlock;
