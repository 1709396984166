import React from "react";
import { ImStarEmpty, ImStarFull, ImStarHalf } from "react-icons/im";

import GoogleLogo from "../../assets/images/google.svg";
import GoogleWhiteLogo from "../../assets/images/google-white.svg";
import StarRatingComponent from "react-star-rating-component";
import GoogleGrey from "../../assets/images/Google_Grey.svg";

const GoogleRatingBlock = ({
	rating,
	isDarkBackground = false,
	alignHorizondally = false,
	isWhiteLogo = false,
}) => {
	return (
		<a
			target="_blank"
			href={
				"https://www.google.com/maps/place/BelgiumImmo/@50.8388982,4.3613067,17z/data=!4m8!3m7!1s0xad1b8bb456c63ded:0x4cc1a81f05594c90!8m2!3d50.8388982!4d4.3634954!9m1!1b1!16s%2Fg%2F11ryh1lzsx"
			}
		>
			<div className="d-flex flex-row flex-wrap align-items-end google-reviews-section">
				<div
					className={`section-1 d-flex ${
						alignHorizondally ? "flex-row align-items-center" : "flex-column"
					}`}
				>
					<img
						className="logo"
						// src={isWhiteLogo ? GoogleWhiteLogo : GoogleLogo}
						src={isWhiteLogo ? GoogleWhiteLogo : GoogleGrey}
						alt="google"
						style={{
							width: alignHorizondally ? 55 : 75,
							height: 25,
							objectFit: "contain",
							...(alignHorizondally && { marginBottom: 4 }),
						}}
					/>
					<div
						className="review-text"
						style={{
							fontSize: alignHorizondally ? 14 : 12,
							color: alignHorizondally
								? "#c4cbdc"
								: isDarkBackground
								? "white"
								: "#6C768F",
							marginTop: -5,
							...(alignHorizondally && { marginLeft: 10 }),
						}}
					>
						Reviews
					</div>
				</div>

				<div
					className="separator"
					style={{
						border: "1px solid rgba(56, 113, 239, 0.25)",
						width: 1,
						margin: "0px 10px",
						...(alignHorizondally
							? { height: 20, marginBottom: 7 }
							: { height: 40 }),
					}}
				/>

				<div
					className={`section-2 d-flex ${
						alignHorizondally ? "flex-row align-items-center" : "flex-column"
					}`}
				>
					<div className="d-flex flex-row align-items-center">
						<div
							style={{
								fontSize: 16,
								fontWeight: "bold",
								color: isDarkBackground ? "white" : "#6C768F",
								marginRight: 8,
							}}
						>
							{parseFloat(rating?.rating).toFixed(1)}
						</div>
						<StarRatingComponent
							name="ib-rate"
							className="mt-1"
							renderStarIcon={(index, value) => {
								if (index <= value) {
									return (
										<ImStarFull
											style={{
												width: 14,
												height: 14,
												color: "#FE7F2D",
												marginRight: 1,
											}}
										/>
									);
								} else {
									return (
										<ImStarEmpty
											style={{
												width: 14,
												height: 14,
												color: "#FE7F2D",
												marginRight: 1,
											}}
										/>
									);
								}
							}}
							renderStarIconHalf={(index: number, value: number) => (
								<ImStarHalf
									style={{
										width: 14,
										height: 14,
										color: "#FE7F2D",
										marginRight: 1,
									}}
								/>
							)}
							starCount={5}
							value={parseFloat(rating?.rating)}
						/>
					</div>
					<div
						className="number-of-reviews-text"
						style={{
							fontSize: 13,
							fontStyle: "italic",
							color: isDarkBackground ? "#A9C0FF" : "#6C768F",
							...(!alignHorizondally ? { marginTop: -10 } : { marginLeft: 10 }),
						}}
					>
						{/* <span className="d-none d-md-block">
							{rating?.number_of_reviews} reviews
						</span> */}
						<span className="d-block">
							{rating?.number_of_reviews} reviews
						</span>
						{/* <span className="d-block d-md-none">{`(${rating?.number_of_reviews})`}</span> */}
					</div>
				</div>
			</div>
		</a>
	);
};

export default GoogleRatingBlock;
