import React from "react";
import CommonComp from "./common/CommonComp";
import SalesMethodEn from "../../../assets/images/estimate-home/sales-method-en.webp";
import SalesMethodFr from "../../../assets/images/estimate-home/sales-method-fr.webp";
import SalesMethodNl from "../../../assets/images/estimate-home/sales-method-nl.webp";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
const SalesMethod = ({ alt }) => {
	const { t } = useTranslation("estimate-home");
	const { locale } = useRouter();

	return (
		<CommonComp
			alt={alt}
			sectionImg={
				locale === "fr"
					? SalesMethodFr
					: locale === "nl"
					? SalesMethodNl
					: SalesMethodEn
			}
			headingText={t("repeat-sale-method")}
			paraOne={t("repeated-sales-paragraph-one")}
			paraTwo={t("repeated-sales-paragraph-two")}
			flexClass={"flex-column flex-md-row-reverse"}
		/>
	);
};

export default SalesMethod;
