import Image from "next/image";
import React from "react";
import styled from "styled-components";
const HeadingContainer = styled.div`
	text-align: start;
	@media (min-width: 768px) {
		text-align: center;
	}

	@media (max-width: 767px) {
		flex: 1;
	}
`;

const ImageContainer = styled.div`
	width: 90px;
	height: 90px;
	position: relative;
	margin-right: 20px;

	@media (min-width: 600px) and (max-width: 768px) {
		width: 120px;
		height: 120px;
	}
	@media (min-width: 769px) {
		width: 172px;
		height: 172px;
	}
`;

const SubHeading = styled.h3`
	font-family: "Nunito Sans";
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	color: var(--mainColor);
	line-height: 25px;
	@media (max-width: 525px) {
		font-size: 16px;
		line-height: 22px;
	}
`;

const ParaDiv = styled.p`
	font-family: "Nunito Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	color: var(--mainColor);
	line-height: 22px;
	@media (max-width: 768px) {
		font-size: 14px;
		line-height: 19px;
	}
`;
const SectionOne = ({ imgSrc, elHeading, para }) => {
	return (
		<div className="col-12 col-md-4 d-flex flex-row flex-md-column align-items-center p-0 px-md-4">
			<ImageContainer className="mb-3">
				<Image
					quality={100}
					objectFit="contain"
					src={imgSrc}
					alt=""
					layout="fill"
				/>
			</ImageContainer>
			<HeadingContainer className="d-flex flex-column p-0">
				<SubHeading>{elHeading ? elHeading : ""}</SubHeading>
				<ParaDiv>{para ? para : ""}</ParaDiv>
			</HeadingContainer>
		</div>
	);
};

export default SectionOne;
